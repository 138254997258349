import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/index.scss"
import { Link } from "gatsby"
import EmailAddress from "../components/emailaddress"

const IndexPage = ({ siteDescription }) => (
  <Layout template="home">
    <Seo
        title="Home"
        description={ siteDescription }/>

    <section className={ `content-section bg-color-cream text-center` }>
        <div className={ `container-lg inner` }>
            <h1>Welcome</h1>
            <p className={ `intro-statement` }>
                M's Mini Farm is a small hobby farm owned and operated by longtime friends Megan and Michelle.
                M's found it's home on the Historic <a href="https://valleygemproperties.com/" target="_blank" rel="noreferrer noopener"><em>Valley Gem Farm</em></a>, 
                located a hop and a skip away from old-town Arlington, WA. 
                Our current lineup includes five goats, three hens, one Rooster and one delightfully curious farm kitty.
                We love our little creatures, and work hard to show it!
            </p>
        </div>
    </section>

    <section id={ `goat_intro` } className={ `featured content-section bg-color-dark-slate text-center border-color-darker-slate` } style={{ backgroundImage: 'url(/images/goats-eating-ivy-opt.jpg)' }}>
        <div className={ `container-lg inner` }>
            <div className={`content-wrapper bottom bg-color-dark-slate`}>
                <h2>The Goats</h2>
                <p>We are the Garys, we are goats. We are rambunctious, crazy, insanely cute and cuddly. Above all things, we love treats and attention. We came to M’s Mini Farm Summer 2021, as just wee little kids, born Spring 2021.</p>
                <Link className={ `btn` } to="/goats">More About the Goats</Link>
            </div>
        </div>
    </section>

    <section id={ `chicken_intro` } className={ `featured content-section bg-color-orange text-center border-color-dark-orange` } style={{ backgroundImage: 'url(/images/chickens-eating-grubbies.jpg)' }}>
        <div className={ `container-lg inner` }>
            <div className={`content-wrapper bottom bg-color-orange`}>
                <h2>The Chickens</h2>
                <p>We the fine feathered hens, also known as the "Charlies" are cautiously friendly. We somehow found our way to M's Mini Farm in March of 2021. We will do most anything for grubbies and bugs.</p>
                <Link className={ `btn` } to="/chickens">More About the Chickens</Link>
            </div>
        </div>
    </section>

    <section className={ `content-section bg-color-cream text-center` }>
        <div className={ `container-lg inner` }>
            <h2>Get in Touch</h2>
            <EmailAddress />
        </div>
    </section>
  </Layout>
)

export default IndexPage
