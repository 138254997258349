import * as React from "react"

const EmailAddress = () => (
    <img
        src="images/email-address.svg"
        alt="Get In Touch"
        loading="lazy"
        className={ `email-address`}
        width={ `500` }
        height={ `16` }
    />
)

export default EmailAddress
